.x-small {
  width: 3.2rem;
  height: 3.2rem;
}
.small {
  width: 4.8rem;
  height: 4.8rem;
}

.medium {
  width: 7.2rem;
  height: 7.2rem;
}

.large {
  width: 14.4rem;
  height: 14.4rem;
}

.auto {
  width: auto;
  height: auto;
}
